import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/video-difusion.png";
import noticias07 from "../../../../static/img/blog/noticias-07-.png";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Development of Investor Relations before a growing market of retail investors'>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias07}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                05 Nov. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  By : Manuel Perez
                </span>
                                <span>
                    Communication
                </span>
                            </div>
                        </div>

                        <h2>Development of Investor Relations before a growing market of retail investors</h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                <p>Nowadays, most of the Investor Relation teams worldwide (IR) are gearing their endeavors towards institutional investors. However, retail investors
                have become an important and powerful player of the investment world, thus generating the need to deploy new strategies that enable IR teams to achieve a
                greater rapprochement with this type of investors; fact which has become evident with the advent of several fintechs and trading platforms such as Marco Polo. </p>

                  <p>The foregoing has been largely driven by the lower entry and capital management costs various brokers have implemented to encourage investment before the impacts
                  of the pandemic. In this regard, so far this year alone, brokerage firms such as TD Ameritrade, Charles Schwab, E-Trade and Robinhood in
                  the United States have opened roughly 608 thousand, 609 thousand, 326 thousand and 3 million new accounts owned by retail investors, respectively.
                  Other examples include Freetrade, based in the United Kingdom, which reported a 90% hike in accounts opened since March; and, the Australian platform
                  Stake, which announced that its number of new users doubled. </p>

                  <p>In Mexico. from January to May 2020, the number of active brokerage accounts posted a 65% climb when compared to that of same period in 2019, given that 160,074 new
                  accounts were registered, 1.5 times more than the number of accounts opened during the last 10 years.</p>

                  <p>Against this background, the brokerage house that has reported the most significant growth in Mexico during this period is GBM, whose active accounts hiked 6.8 times from
                  23,947 in May 2019 to 186,512 accounts in 2020. With this, GBM already boasts over 40% of Mexico’s active accounts. </p>

                  <p>All of the above, coupled with the wide range of existing products, has led to retail investors currently representing between 20% and 25% of daily trading
                  activity in the U.S. financial markets, according to Citadel Securities, a share at least two times higher than that of 2019. Therefore, since, collectively,
                  this type of investor can cause unexpected volatility (as they tend to have a short/medium-term vision and a lower resilience to challenging scenario) it is
                  essential to maintain a close and efficient communication with this group of investors, which may be achieved through the following recommendations:  </p>

                   <ul class="list">
                     <li class="icon-arrows-cw"><b>Participate in investment forums or webinars.</b> Taking part at these events, enables for keeping up to date on the rationale behind investor
                     decisions, which could be useful when addressing doubts and sharing company perspectives. </li>
                     <li class="icon-arrows-cw"><b>Stick to a plain language in all media and maintain accessibility.</b> It is common for retail investors to remain with
                     doubts, as they are not properly addressed in the companies’ earnings and / or press releases, IR reports or websites, due to lack of graphic material or
                     the use of terms that are not adequately explained. A key aspect to tackle this situation is to maintain the websites updated with relevant information,
                     tapping into the use of graphic material and a clear structure, as visiting the corporate website is typically the first approach an investor has with a
                     company.  </li>
                     <li class="icon-arrows-cw"><b>Provide financial information.</b> In view of the heightened levels of nervousness that feature retail investors, the adequate disclosure of
                     financial information is of utmost importance in order to contribute to the stability of the instruments’ quote. </li>
                     <li class="icon-arrows-cw"><b>Establish policies to properly address IR inquiries.</b>  It is important to delegate the responsibilities of investor communication, from responding e-mails
                     to phone calls, always striving for a satisfactory coordination of communication departments to avoid generating confusion or duplicating efforts.  </li>
                     <li class="icon-arrows-cw"><b>Consider increasing the frequency of IR endeavors.</b>  In a number of companies, investor relations are limited to the issuance of financial reports and the
                     upkeeping of the IR website. For this reason, extending communication coverage by means of interviews, press releases, social networks for professionals
                     (such as Linkedin), among others, will certainly strengthen the company’s market awareness, mainly among retail investors.  </li>
                   </ul>

                   <p>Bibliographic references
                     <ul>
                       <li>Ashwell, B. (2020). How IR should respond to the increase in retail investors. October 7<sup>th</sup>, 2020.  <a href="https://www.irmagazine.com/shareholder-targeting-id/how-ir-should-respond-increase-retail-investors" target="_blank"> IR Magazine</a>.</li>
                       <li>Lara, R. (September 25<sup>th</sup>, 2020). ¿Qué hiciste en la pandemia? La respuesta de muchas personas: invertir en Bolsa.  <a href="https://expansion.mx/mercados/2020/09/25/que-hiciste-confinamiento-mucha-gente-empezo-invertir-bolsa" target="_blank"> Expansión</a>.</li>
                       <li>Gilmore, A. (2020). Investor Intelligence: The Rise of Retail. August 26<sup>th</sup>, 2020.  <a href="https://www.nasdaq.com/articles/investor-intelligence%3A-the-rise-of-retail-2020-08-26" target="_blank"> Nasdaq</a>.</li>
                       <li>Vallie. M. (2018). The rise of retail investors: How public companies should manage the masses. February 14<sup>th</sup>, 2018.  <a href="https://westwicke.com/2018/02/rise-retail-investors-public-companies-manage-masses/" target="_blank"> Westwicke</a>.</li>
                     </ul>
                   </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
